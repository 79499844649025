import Vue from 'vue'
import VueRouter from 'vue-router'
import UnderConstruction from '@/views/UnderConstruction.vue'
import Imprint from '@/views/Imprint.vue'
import DataProtection from '@/views/DataProtection.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/imprint',
        name: 'Imprint',
        component: Imprint
    },
    {
        path: '/dataprotection',
        name: 'DataProtection',
        component: DataProtection
    },
    {
        path: '/*',
        name: 'UnderConstruction',
        component: UnderConstruction
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
