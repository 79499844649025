<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Impressum</h1>

                <h2>Angaben gemäß § 5 TMG</h2>
                <p>
                    Johannes Eimer Production (JEP)
                    <br />Kommodore-Johnsen-Boulevard 32 <br />28217 Bremen
                </p>
                <p>
                    <b>Vertreten durch:</b>
                    <br />Johannes Eimer
                </p>
                <p>
                    <b>Kontakt:</b>
                    <br />Telefon: +49 (0) 151 2707 8651 <br />E-Mail:
                    info@jep-dev.com
                </p>
                <p>
                    <b>Umsatzsteuer-ID:</b>
                    <br />Umsatzsteuer-Identifikationsnummer gemäß §27a
                    Umsatzsteuergesetz: DE288106334
                </p>
                <p>
                    <b>Wirtschafts-ID:</b>
                    <br />60/217/12742
                </p>
                <h2>Haftung für Inhalte</h2>
                <p>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt
                    erstellt. Für die Richtigkeit, Vollständigkeit und
                    Aktualität der Inhalte können wir jedoch keine Gewähr
                    übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
                    für eigene Inhalte auf diesen Seiten nach den allgemeinen
                    Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                    gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                    Nutzung von Informationen nach den allgemeinen Gesetzen
                    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                    jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung möglich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte
                    umgehend entfernen.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
.container {
    padding-top: 25px;
    padding-bottom: 25px;
}
</style>
