<template>
    <div class="container">
        <div class="content">
            <div class="text">
                <img src="@/assets/logo.png">
            </div>
            <div class="text">
                Under Construction
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.text {
    text-align: center;
    margin-bottom: 15px;
    font-size: 28px;
    font-weight: 500;
}
img {
    width: 100%;
    height: auto;
    margin-top: 75%;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
}
.content {
    align-self: center;
}
</style>
