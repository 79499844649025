<template>
    <footer class="footer">
        <div class="container">
            <a><router-link to="/imprint">Impressum</router-link></a>
            <a><router-link to="/dataprotection">Datenschutz</router-link></a>
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style scoped>
    .footer {
        height: 50px;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        background-color: white;
    }
    a {
        margin: 10px;
        transition: ease-in-out .3s;
        font-weight: 500 !important;
        color: black !important;
        text-decoration: none !important;
    }
    a:hover {
        cursor: pointer;
        color: #5d78ff !important;
    }
</style>
